












































































































import BaseButton from '@/components/base/BaseButton.vue';
import PaymentInput from '@/components/PaymentInput.vue';
import { useAuthStore } from '@/composables/useAuthStore';
import { SubscribedResponse } from '@/composables/useBookedResources/types';
import MemberPage from '@/layouts/MemberLayout/MemberPage.vue';
import { defineComponent, onMounted, ref } from '@/plugins/composition';
import { routeNames } from '@/router/routeNames';
import { useRouter } from '@/router/useRouter';
import { formatDate } from '@/utils/date';
import { useLoader } from '@/composables/useLoader';

import { formatPrice } from '@/utils/number';

export default defineComponent({
  components: { PaymentInput, MemberPage, BaseButton },
  name: 'SubscribePage',
  setup() {
    const sub = ref<SubscribedResponse | null>(null);
    const price = ref(0);

    const { router } = useRouter();
    const dialog = ref(true);
    const { refreshState } = useAuthStore();
    const { user, isAuthed, isMember } = useAuthStore();
    const accountTypeName = ref('');
    const { loading, withLoader } = useLoader({ initial: true });

    onMounted(async () => {
      withLoader(async () => {
        await refreshState();
        accountTypeName.value = user.value.data.customAttributes.account_type == 'Arts_Organisation' ? 'Arts Organisation' : 'Individual';
        price.value = await user.value.getSubscriptionPrice();
      });
    });

    const goToBilling = async () => {
      await refreshState();
      router.push({ name: routeNames.spaces.book });
    };

    const goHome = () => {
      if (!isAuthed.value) {
        return router.push('/');
      }

      if (!isMember.value) {
        return router.push('/');
      }

      router.push({ name: routeNames.dashboard });
    };

    const close = () => {
      dialog.value = false;
      router.push({name: routeNames.profile.billing});
    }


    const susbcribed = (_sub: SubscribedResponse) => {
      sub.value = _sub;
      dialog.value = true;
    };

    return {
      goToBilling,
      susbcribed,
      dialog,
      formatDate,
      sub,
      formatPrice,
      price,
      user,
      routeNames,
      goHome,
      accountTypeName,
      loading,
      close
    };
  },
});
